/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.138.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ErrorRest } from '../models';
// @ts-ignore
import type { SystemParameterRest } from '../models';
/**
 * SystemParameterResourceApi - axios parameter creator
 * @export
 */
export const SystemParameterResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Löscht einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht einen Systemparameter.
         * @param {string} paramName Name des Systemparameters welches gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystemparameter: async (paramName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramName' is not null or undefined
            assertParamExists('deleteSystemparameter', 'paramName', paramName)
            const localVarPath = `/konfigurationen/systemparameter/{paramName}`
                .replace(`{${"paramName"}}`, encodeURIComponent(String(paramName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Systemparameter.
         * @param {boolean} [all] Wenn all &#x3D; true werden alle Systemparameter übermittelt, sonst nur die nicht erweiterten Systemparameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSystemparameter: async (all?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/systemparameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert einen Systemparameter.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemParameter: async (paramName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramName' is not null or undefined
            assertParamExists('getSystemParameter', 'paramName', paramName)
            const localVarPath = `/konfigurationen/systemparameter/{paramName}`
                .replace(`{${"paramName"}}`, encodeURIComponent(String(paramName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liefert einen Systemparameter ohne Authentifizierung.
         * @summary Liefert einen Systemparameter ohne Authentifizierung.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemParameterWithoutAuthentication: async (paramName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramName' is not null or undefined
            assertParamExists('getSystemParameterWithoutAuthentication', 'paramName', paramName)
            const localVarPath = `/unauthenticated/konfigurationen/systemparameter/{paramName}`
                .replace(`{${"paramName"}}`, encodeURIComponent(String(paramName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {string} paramName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemparameterBinary: async (paramName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paramName' is not null or undefined
            assertParamExists('getSystemparameterBinary', 'paramName', paramName)
            const localVarPath = `/konfigurationen/systemparameter/{paramName}/datei`
                .replace(`{${"paramName"}}`, encodeURIComponent(String(paramName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}. Neue Parameter werden hinzugefuegt, vorhandene Parameter  ueberschrieben.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}.
         * @param {Array<SystemParameterRest>} systemParameterRest Liste neuer Systemparameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSystemParameter: async (systemParameterRest: Array<SystemParameterRest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemParameterRest' is not null or undefined
            assertParamExists('importSystemParameter', 'systemParameterRest', systemParameterRest)
            const localVarPath = `/konfigurationen/systemparameter/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(systemParameterRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Addiert einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Addiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSystemParameter: async (systemparameter: SystemParameterRest, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemparameter' is not null or undefined
            assertParamExists('insertSystemParameter', 'systemparameter', systemparameter)
            const localVarPath = `/konfigurationen/systemparameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (systemparameter !== undefined) { 
                localVarFormParams.append('systemparameter', new Blob([JSON.stringify(systemparameter)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {string} paramName Name des Systemparameters
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemParameterData: async (systemparameter: SystemParameterRest, paramName: string, file?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'systemparameter' is not null or undefined
            assertParamExists('updateSystemParameterData', 'systemparameter', systemparameter)
            // verify required parameter 'paramName' is not null or undefined
            assertParamExists('updateSystemParameterData', 'paramName', paramName)
            const localVarPath = `/konfigurationen/systemparameter/{paramName}`
                .replace(`{${"paramName"}}`, encodeURIComponent(String(paramName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (systemparameter !== undefined) { 
                localVarFormParams.append('systemparameter', new Blob([JSON.stringify(systemparameter)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemParameterResourceApi - functional programming interface
 * @export
 */
export const SystemParameterResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemParameterResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Löscht einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht einen Systemparameter.
         * @param {string} paramName Name des Systemparameters welches gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSystemparameter(paramName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSystemparameter(paramName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.deleteSystemparameter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Systemparameter.
         * @param {boolean} [all] Wenn all &#x3D; true werden alle Systemparameter übermittelt, sonst nur die nicht erweiterten Systemparameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSystemparameter(all?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemParameterRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSystemparameter(all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.getAllSystemparameter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert einen Systemparameter.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemParameter(paramName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemParameterRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemParameter(paramName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.getSystemParameter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Liefert einen Systemparameter ohne Authentifizierung.
         * @summary Liefert einen Systemparameter ohne Authentifizierung.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemParameterWithoutAuthentication(paramName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemParameterRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemParameterWithoutAuthentication(paramName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.getSystemParameterWithoutAuthentication']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {string} paramName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemparameterBinary(paramName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemparameterBinary(paramName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.getSystemparameterBinary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}. Neue Parameter werden hinzugefuegt, vorhandene Parameter  ueberschrieben.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}.
         * @param {Array<SystemParameterRest>} systemParameterRest Liste neuer Systemparameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importSystemParameter(systemParameterRest: Array<SystemParameterRest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importSystemParameter(systemParameterRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.importSystemParameter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Addiert einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Addiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertSystemParameter(systemparameter: SystemParameterRest, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemParameterRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertSystemParameter(systemparameter, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.insertSystemParameter']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {string} paramName Name des Systemparameters
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSystemParameterData(systemparameter: SystemParameterRest, paramName: string, file?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemParameterRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSystemParameterData(systemparameter, paramName, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SystemParameterResourceApi.updateSystemParameterData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SystemParameterResourceApi - factory interface
 * @export
 */
export const SystemParameterResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemParameterResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Löscht einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht einen Systemparameter.
         * @param {string} paramName Name des Systemparameters welches gelöscht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSystemparameter(paramName: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteSystemparameter(paramName, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Liefert alle Systemparameter.
         * @param {boolean} [all] Wenn all &#x3D; true werden alle Systemparameter übermittelt, sonst nur die nicht erweiterten Systemparameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSystemparameter(all?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<SystemParameterRest>> {
            return localVarFp.getAllSystemparameter(all, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert einen Systemparameter.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemParameter(paramName: string, options?: RawAxiosRequestConfig): AxiosPromise<SystemParameterRest> {
            return localVarFp.getSystemParameter(paramName, options).then((request) => request(axios, basePath));
        },
        /**
         * Liefert einen Systemparameter ohne Authentifizierung.
         * @summary Liefert einen Systemparameter ohne Authentifizierung.
         * @param {string} paramName Name des Systemparameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemParameterWithoutAuthentication(paramName: string, options?: RawAxiosRequestConfig): AxiosPromise<SystemParameterRest> {
            return localVarFp.getSystemParameterWithoutAuthentication(paramName, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {string} paramName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemparameterBinary(paramName: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getSystemparameterBinary(paramName, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}. Neue Parameter werden hinzugefuegt, vorhandene Parameter  ueberschrieben.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}.
         * @param {Array<SystemParameterRest>} systemParameterRest Liste neuer Systemparameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSystemParameter(systemParameterRest: Array<SystemParameterRest>, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.importSystemParameter(systemParameterRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Addiert einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Addiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertSystemParameter(systemparameter: SystemParameterRest, file?: File, options?: RawAxiosRequestConfig): AxiosPromise<SystemParameterRest> {
            return localVarFp.insertSystemParameter(systemparameter, file, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Aktualisiert einen Systemparameter.
         * @param {SystemParameterRest} systemparameter 
         * @param {string} paramName Name des Systemparameters
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSystemParameterData(systemparameter: SystemParameterRest, paramName: string, file?: File, options?: RawAxiosRequestConfig): AxiosPromise<SystemParameterRest> {
            return localVarFp.updateSystemParameterData(systemparameter, paramName, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemParameterResourceApi - object-oriented interface
 * @export
 * @class SystemParameterResourceApi
 * @extends {BaseAPI}
 */
export class SystemParameterResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Löscht einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Löscht einen Systemparameter.
     * @param {string} paramName Name des Systemparameters welches gelöscht werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public deleteSystemparameter(paramName: string, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).deleteSystemparameter(paramName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert alle Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Liefert alle Systemparameter.
     * @param {boolean} [all] Wenn all &#x3D; true werden alle Systemparameter übermittelt, sonst nur die nicht erweiterten Systemparameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public getAllSystemparameter(all?: boolean, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).getAllSystemparameter(all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert einen Systemparameter.
     * @param {string} paramName Name des Systemparameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public getSystemParameter(paramName: string, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).getSystemParameter(paramName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Liefert einen Systemparameter ohne Authentifizierung.
     * @summary Liefert einen Systemparameter ohne Authentifizierung.
     * @param {string} paramName Name des Systemparameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public getSystemParameterWithoutAuthentication(paramName: string, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).getSystemParameterWithoutAuthentication(paramName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {string} paramName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public getSystemparameterBinary(paramName: string, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).getSystemparameterBinary(paramName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}. Neue Parameter werden hinzugefuegt, vorhandene Parameter  ueberschrieben.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Importiert eine Liste an {@link SystemParameterRest SystemParameterRest}.
     * @param {Array<SystemParameterRest>} systemParameterRest Liste neuer Systemparameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public importSystemParameter(systemParameterRest: Array<SystemParameterRest>, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).importSystemParameter(systemParameterRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Addiert einen Systemparameter. BSW-115</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Addiert einen Systemparameter.
     * @param {SystemParameterRest} systemparameter 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public insertSystemParameter(systemparameter: SystemParameterRest, file?: File, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).insertSystemParameter(systemparameter, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiert einen Systemparameter.</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Aktualisiert einen Systemparameter.
     * @param {SystemParameterRest} systemparameter 
     * @param {string} paramName Name des Systemparameters
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemParameterResourceApi
     */
    public updateSystemParameterData(systemparameter: SystemParameterRest, paramName: string, file?: File, options?: RawAxiosRequestConfig) {
        return SystemParameterResourceApiFp(this.configuration).updateSystemParameterData(systemparameter, paramName, file, options).then((request) => request(this.axios, this.basePath));
    }
}

